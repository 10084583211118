.filter-bar {
    width: 100%;

    height: 60px;

    padding: 0px 20px 10px 0px;

    display: flex;
    justify-content: left;
    align-items: center;

    background-color: #fff;

    &--flex-end {
        padding: 35px 150px 10px 55px;

        align-items: flex-end;
    }

    &--flex-start {
        padding: 35px 150px 10px 55px;

        align-items: flex-start;
    }

    &--right {
        padding: 35px 150px 10px 55px;

        justify-content: flex-end;
    }

    &--bottom {
        padding-bottom: 50px;
    }

    &-data {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-title {
        margin-bottom: 10px;

        color: #000;
        font-size: 16px;
        font-weight: 400;

        &--bold {
            font-size: 18px;
            font-weight: bold;
        }
    }

    &-value {
        color: #000;
        font-size: 14px;
        font-weight: bold;
    }

    &-inputs {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        // margin-top: 40px;

        & .btn {
            margin: 0 !important;
            margin-left: 20px !important;
        }
    }

    &-right {
        text-align: right;
    }

    &-box{
        margin-left: 30px;

        &:first-child {
            margin-left: 0;
        }

        &--right {
            margin-left: 10px;

            &:first-child {
                margin-left: 0;
            }
        }

        &--search {
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }

        &--calendar {
            display: block
        }
    }

}

.input-custom {
    width: auto !important;
    height: auto !important;
    max-width: 140px !important;

    margin: 0 5px !important;
    padding: 10px !important;

    color: #000 !important;

    &-wrapper {
        &--margin {
            &-bottom {

                margin-bottom: 30px;
            }
        }
    }

    &--textarea {
        min-height: 80px;

        vertical-align: top;
    }

    &--date {
        max-width: 150px !important;

        &:first-child {
            margin-right: 10px !important;
        }
    }

    &--fullName {
        margin: 0 !important;
        margin-left: 5px !important;
    }

    &-label {
        margin-bottom: 5px;
        padding-left: 5px;

        color: #000;
        font-size: 14px;
        font-weight: 400;
    }
}

.select-custom {
    width: 240px !important;

    margin: 0 5px !important;
    padding: 10px !important;

    color: #000 !important;

    border: 1px solid #e6e6e6 !important;
}

.btn-search {
    width: 24px;
    height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    border: 0 !important;

    background-color: #E6E6E6;

    transition: all .2s ease-in;

    cursor: pointer;

    &:hover {
        opacity: .9;
        }

    &-img {
        width: auto;
        height: 25px;

        opacity: .6;
    }

    &-absolute {
        position: absolute;
        top: 50%;
        left: 20px;

        transform: translateY(-50%);
    }
}

.order-input {
}